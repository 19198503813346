import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '../../assets/images/handle/cmm.png';
import _imports_1 from '../../assets/images/handle/jb.png';
import _imports_2 from '../../assets/images/handle/sc.png';
var _hoisted_1 = { class: "yc-handle" };
var _hoisted_2 = {
    key: 1,
    class: "clickable"
};
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import feedback from '../../utils/feedback';
import request from '../../api/request';
import { useStore } from 'vuex';
import chat from '../../utils/AI/chat';
export default /*@__PURE__*/ _defineComponent({
    __name: 'yc-handle',
    props: {
        datas: {},
        showRename: { type: Boolean, default: true },
        showDelete: { type: Boolean, default: true },
        showJunbao: { type: Boolean, default: false }
    },
    emits: ['init', 'close', 'update'],
    setup: function (__props, _a) {
        var __expose = _a.expose, __emit = _a.emit;
        var store = useStore();
        var emits = __emit;
        var props = __props;
        onMounted(function () {
            document.addEventListener('mousedown', handleClickOutside);
            var element = document.querySelector('.yc_scrollview');
            element === null || element === void 0 ? void 0 : element.addEventListener('scroll', handleScroll, { passive: true });
        });
        onBeforeUnmount(function () {
            document.removeEventListener('mousedown', handleClickOutside);
            var element = document.querySelector('.yc_scrollview');
            element === null || element === void 0 ? void 0 : element.removeEventListener('scroll', handleScroll);
        });
        var visible = ref(false);
        var popoverRef = ref();
        var open = function () {
            visible.value = true;
        };
        var close = function () {
            visible.value = false;
        };
        var handleClickOutside = function (event) {
            var _a;
            if (popoverRef.value && !((_a = popoverRef.value) === null || _a === void 0 ? void 0 : _a.$el.contains(event.target))) {
                visible.value = false; // 如果点击的元素不在 el-popover 内部，隐藏它
                emits('close');
            }
        };
        var handleScroll = function () {
            close();
        };
        var handleChange = function (handleName, item) {
            switch (handleName) {
                case 'del':
                    feedback
                        .confirm('是否删除该条消息')
                        .then(function (res) {
                        if (res === 'confirm') {
                            if (item.modelName == 'translation' || item.modelName == 'meeting') {
                                deleteHistory(item);
                            }
                            else if (item.modelName == 'drawing') {
                                deleteDrawChatById(item.id);
                            }
                            else if (item.modelName == 'music') {
                                deleteMusicChatById(item.id);
                            }
                            else if (item.modelData == 'znt') {
                                deleteZntChatById(item.id);
                            }
                            else {
                                deleteChatById(item.id);
                            }
                        }
                    })
                        .catch(function (err) { });
                    break;
                case 'rename':
                    ElMessageBox.prompt('编辑对话名称', '', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        inputValue: item.title,
                        inputPattern: /\S/,
                        inputErrorMessage: '请输入对话名称'
                    })
                        .then(function (_a) {
                        var value = _a.value;
                        if (item.modelName == 'translation' || item.modelName == 'meeting') {
                            updateHistory(item, value);
                        }
                        else if (item.modelName == 'drawing') {
                            updateDrawChat(item.id, value);
                        }
                        else if (item.modelName == 'music') {
                            updateMusicChat(item.id, value);
                        }
                        else {
                            updateChat(item.id, item.modelId, value);
                        }
                    })
                        .catch(function () { });
                    break;
            }
        };
        // 更新对话
        var updateChat = function (id, modelId, value) {
            chat
                .updateChat(id, modelId, value)
                .then(function (res) {
                store.commit('setHistroyList');
                emits('update');
            })
                .catch(function () { });
        };
        // 更新绘画对话
        var updateDrawChat = function (id, value) {
            chat
                .updateDrawChat(id, value)
                .then(function (res) {
                store.commit('setDrawHistroyList');
                emits('update');
            })
                .catch(function () { });
        };
        // 更新音乐对话
        var updateMusicChat = function (id, value) {
            chat
                .updateMusicChat(id, value)
                .then(function (res) {
                store.commit('setDrawHistroyList');
                emits('update');
            })
                .catch(function () { });
        };
        // 删除智能体
        var deleteZntChatById = function (id) {
            request.delete("/app-api/lt/ai/chat/conversation/delete-my?id=".concat(id), {}).then(function (res) {
                if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                    feedback.msgSuccess((res === null || res === void 0 ? void 0 : res.msg) || '操作成功');
                    close();
                    emits('init');
                    emits('update');
                    store.dispatch('intelligence/getHistoryList');
                }
            });
        };
        // 删除对话
        var deleteChatById = function (id) {
            request.delete("/app-api/lt/ai/chat/conversation/delete-my?id=".concat(id), {}).then(function (res) {
                if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                    feedback.msgSuccess((res === null || res === void 0 ? void 0 : res.msg) || '操作成功');
                    close();
                    emits('init');
                    emits('update');
                    store.commit('setHistroyList');
                }
            });
        };
        // 删除绘画对话
        var deleteDrawChatById = function (id) {
            request.delete("/app-api/lt/ai/image/deleteSessionById?id=".concat(id), {}).then(function (res) {
                if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                    feedback.msgSuccess((res === null || res === void 0 ? void 0 : res.msg) || '操作成功');
                    close();
                    emits('init');
                    emits('update');
                    store.commit('setDrawHistroyList');
                }
                else {
                    ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                }
            });
        };
        // 删除音乐对话
        var deleteMusicChatById = function (id) {
            request.delete("/app-api/lt/ai/volc/music/delete?id=".concat(id), {}).then(function (res) {
                if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                    feedback.msgSuccess((res === null || res === void 0 ? void 0 : res.msg) || '操作成功');
                    close();
                    emits('init');
                    emits('update');
                }
                else {
                    ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                }
            });
        };
        // 删除会议和同声历史
        var deleteHistory = function (item) {
            var url = item.modelName == 'translation' ? 'translation/deleteById' : 'meeting/deleteById';
            request.post("/app-api/lt/ai/".concat(url), {
                id: item === null || item === void 0 ? void 0 : item.id,
            }).then(function (res) {
                if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                    feedback.msgSuccess((res === null || res === void 0 ? void 0 : res.msg) || '操作成功');
                    close();
                    emits('update');
                }
                else {
                    ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                }
            });
        };
        // 更新
        var updateHistory = function (item, value) {
            var url = item.modelName === 'translation' ? 'translation/updateById' : 'meeting/updateMeetingRecord';
            request.post("/app-api/lt/ai/".concat(url), {
                id: item === null || item === void 0 ? void 0 : item.id,
                name: value
            }).then(function (res) {
                if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                    feedback.msgSuccess((res === null || res === void 0 ? void 0 : res.msg) || '操作成功');
                    close();
                    emits('update');
                }
                else {
                    ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                }
            });
        };
        __expose({
            open: open,
            close: close
        });
        return function (_ctx, _cache) {
            var _component_el_popover = _resolveComponent("el-popover");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_popover, {
                    ref_key: "popoverRef",
                    ref: popoverRef,
                    "popper-class": "yhPopover",
                    placement: "bottom-end",
                    title: "",
                    width: 160,
                    visible: visible.value,
                    trigger: "click",
                    content: ""
                }, {
                    reference: _withCtx(function () { return [
                        _renderSlot(_ctx.$slots, "default")
                    ]; }),
                    default: _withCtx(function () { return [
                        _createElementVNode("div", {
                            class: "yh_wrap",
                            onClick: _cache[2] || (_cache[2] = _withModifiers(function () { }, ["stop"]))
                        }, [
                            (_ctx.showRename)
                                ? (_openBlock(), _createElementBlock("p", {
                                    key: 0,
                                    class: "clickable",
                                    onClick: _cache[0] || (_cache[0] = function ($event) { return (handleChange('rename', _ctx.datas)); })
                                }, _cache[3] || (_cache[3] = [
                                    _createElementVNode("img", { src: _imports_0 }, null, -1),
                                    _createElementVNode("span", null, "重命名", -1)
                                ])))
                                : _createCommentVNode("", true),
                            (_ctx.showJunbao)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_2, _cache[4] || (_cache[4] = [
                                    _createElementVNode("img", { src: _imports_1 }, null, -1),
                                    _createElementVNode("span", null, "举报", -1)
                                ])))
                                : _createCommentVNode("", true),
                            (_ctx.showDelete)
                                ? (_openBlock(), _createElementBlock("p", {
                                    key: 2,
                                    class: "clickable",
                                    onClick: _cache[1] || (_cache[1] = function ($event) { return (handleChange('del', _ctx.datas)); })
                                }, _cache[5] || (_cache[5] = [
                                    _createElementVNode("img", { src: _imports_2 }, null, -1),
                                    _createElementVNode("span", { class: "d_color" }, "删除", -1)
                                ])))
                                : _createCommentVNode("", true)
                        ])
                    ]; }),
                    _: 3
                }, 8, ["visible"])
            ]));
        };
    }
});
