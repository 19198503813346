var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createRouter, createWebHashHistory } from 'vue-router';
import layout from '../views/layout/index.vue';
var routes = [
    {
        path: '/',
        name: 'home',
        component: layout,
        redirect: '/new-chat',
        children: [
            {
                // AI对话
                path: '/new-chat',
                name: '/new-chat',
                component: function () { return import('../views/subview/new-chat/new-chat.vue'); }
            },
            {
                // AI写作
                path: '/writing',
                name: '/writing',
                component: function () { return import('../views/subview/writing/writing.vue'); }
            },
            {
                // 性格测试
                path: '/mbti',
                name: '/mbti',
                component: function () { return import('../views/subview/mbti/mbti.vue'); }
            },
            {
                // AI翻译
                path: '/translate',
                name: '/translate',
                component: function () { return import('../views/subview/translate/translate.vue'); }
            },
            {
                // 最近对话
                path: '/history-chat',
                name: '/history-chat',
                meta: {
                    labe: '历史对话',
                    is_login: true
                },
                component: function () { return import('../views/subview/history-chat/history-chat.vue'); }
            },
            {
                // 对话
                path: '/chat',
                name: '/chat',
                meta: {
                    labe: '',
                    is_login: true
                },
                component: function () { return import('../views/subview/chat/chat.vue'); }
            },
            {
                // 对话
                path: '/drawing_chat',
                name: '/drawing_chat',
                component: function () { return import('../views/subview/drawing/drawing_chat/drawing_chat.vue'); }
            },
            {
                // 对话
                path: '/drawing',
                name: '/drawing',
                component: function () { return import('../views/subview/drawing/drawing.vue'); }
            },
            {
                // 音乐生成
                path: '/music',
                name: '/music',
                component: function () { return import('../views/subview/music/music.vue'); }
            },
            {
                // ppt生成
                path: '/ppt',
                name: '/ppt',
                component: function () { return import('../views/ppt/index.vue'); },
                redirect: '/pptMake',
                children: [
                    {
                        // 首页
                        path: '/pptMake',
                        name: '/pptMake',
                        meta: {
                            labe: 'PPT制作'
                        },
                        component: function () { return import('../views/ppt/home/home.vue'); }
                    },
                    {
                        // 大纲
                        path: '/outline',
                        name: '/outline',
                        meta: {
                            labe: 'PPT大纲',
                            is_login: true
                        },
                        component: function () { return import('../views/ppt/outline/outline.vue'); }
                    },
                    {
                        // 主题模版
                        path: '/stencilTheme',
                        name: '/stencilTheme',
                        meta: {
                            labe: 'PPT模版',
                            is_login: true
                        },
                        component: function () { return import('../views/ppt/stencilTheme/stencilTheme.vue'); }
                    },
                    {
                        // 预览
                        path: '/preview',
                        name: '/preview',
                        meta: {
                            labe: 'PPT预览',
                            is_login: true
                        },
                        component: function () { return import('../views/ppt/preview/preview.vue'); }
                    }
                ]
            },
            {
                // 智能体
                path: '/intelligence',
                name: '/intelligence',
                meta: {
                    labe: '智能体'
                },
                component: function () { return import('../views/subview/intelligence/intelligence.vue'); },
                redirect: '/intelligence_list',
                children: [
                    {
                        // 智能体列表
                        path: '/intelligence_list',
                        name: '/intelligence_list',
                        meta: {
                            labe: '选择智能体',
                            is_login: false
                        },
                        component: function () { return import('../views/subview/intelligence/intelligence_list/intelligence_list.vue'); }
                    },
                    {
                        // 智能体-新对话
                        path: '/intelligence_chat',
                        name: '/intelligence_chat',
                        meta: {
                            labe: '新对话',
                            is_login: true
                        },
                        component: function () { return import('../views/subview/intelligence/intelligence_chat/intelligence_chat.vue'); }
                    },
                    {
                        // 智能体-文件转换
                        path: '/intelligence_fileConversion',
                        name: '/intelligence_fileConversion',
                        meta: {
                            labe: '新对话',
                            is_login: true
                        },
                        component: function () { return import('../views/subview/intelligence/intelligence_fileConversion/intelligence_fileConversion.vue'); }
                    }
                ]
            },
            {
                // 图像理解
                path: '/imagecomprehend',
                name: '/imagecomprehend',
                meta: {
                    labe: '图像理解'
                },
                component: function () { return import('../views/subview/imagecomprehend/imagecomprehend.vue'); }
            },
            {
                // 语音通话
                path: '/voicecall',
                name: '/voicecall',
                meta: {
                    labe: '语音通话',
                    is_login: true
                },
                component: function () { return import('../views/subview/voicecall/voicecall.vue'); }
            },
            {
                // deepseek
                path: '/deepseek',
                name: '/deepseek',
                meta: {
                    labe: '语音通话',
                    is_login: true
                },
                component: function () { return import('../views/subview/deepseek/deepseek.vue'); }
            },
            {
                // 设置
                path: '/setting',
                name: '/setting',
                meta: {
                    labe: '设置',
                    is_login: true
                },
                component: function () { return import('../views/subview/setting/setting.vue'); }
            },
            {
                // AI会议
                path: '/meeting',
                redirect: '/meeting/introduce',
                children: [
                    // AI会议-入口页
                    {
                        path: '/meeting/introduce',
                        name: '/meeting/introduce',
                        meta: {
                            labe: 'AI会议',
                            is_login: false
                        },
                        component: function () { return import('../views/subview/meeting/meeting_introduce/meeting_introduce.vue'); }
                    },
                    // AI会议-会议室
                    {
                        path: '/meeting/room',
                        name: '/meeting/room',
                        meta: {
                            labe: 'AI会议',
                            is_login: false
                        },
                        component: function () { return import('../views/subview/meeting/meeting_room/meeting_room.vue'); }
                    },
                    // AI会议-音频转文字
                    {
                        path: '/meeting/audioToText',
                        name: '/meeting/audioToText',
                        meta: {
                            labe: '音视频转文字',
                            is_login: false
                        },
                        component: function () { return import('../views/subview/meeting/meeting_audioToText/meeting_audioToText.vue'); }
                    },
                    // AI会议-详情
                    {
                        path: '/meeting/detail',
                        name: '/meeting/detail',
                        meta: {
                            labe: 'AI会议',
                            is_login: false
                        },
                        component: function () { return import('../views/subview/meeting/meeting_detail/meeting_detail.vue'); }
                    },
                ]
            },
            {
                // 同声传译
                path: '/simultaneousTranslation',
                meta: {},
                redirect: '/simultaneousTranslation/introduce',
                children: [{
                        path: '/simultaneousTranslation/introduce',
                        name: '/simultaneousTranslation/introduce',
                        meta: {
                            labe: '同声传译',
                            is_login: false
                        },
                        component: function () { return import('../views/subview/simultaneousTranslation/simultaneousTranslation_introduce/simultaneousTranslation_introduce.vue'); }
                    }, {
                        path: '/simultaneousTranslation/generate',
                        name: '/simultaneousTranslation/generate',
                        meta: {
                            labe: '同声传译',
                            is_login: false
                        },
                        component: function () { return import('../views/subview/simultaneousTranslation/simultaneousTranslation_generate/simultaneousTranslation_generate.vue'); }
                    }]
            },
            {
                path: '/chuangkit',
                name: '/chuangkit',
                meta: {
                    labe: '创客贴',
                    is_login: true
                },
                component: function () { return import('../views/subview/chuangkit/chuangkit.vue'); }
            }
        ]
    }
];
var router = createRouter({
    history: createWebHashHistory(),
    routes: routes
});
import config from '../api/config';
import store from '../store/index';
// 全局导航守卫
import emitter from '../utils/eventBus';
import feedback from '@/utils/feedback';
router.beforeEach(function (to, from, next) { return __awaiter(void 0, void 0, void 0, function () {
    var tipsRoute, baiRoute, tipsRes, error_1, token;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if ((_a = to === null || to === void 0 ? void 0 : to.query) === null || _a === void 0 ? void 0 : _a.device_id) {
                    localStorage.setItem('device_id', ((_b = to === null || to === void 0 ? void 0 : to.query) === null || _b === void 0 ? void 0 : _b.device_id) || '');
                }
                emitter.emit('closeMusic');
                // 网站路径名称
                if ((_c = to.meta) === null || _c === void 0 ? void 0 : _c.label) {
                    document.title = to.meta.label;
                }
                else {
                    document.title = config.brand_name; // 默认的title
                }
                // 访问路径不属于当前系统，则打开首页
                if (to.matched.length == 0)
                    return [2 /*return*/, next('/')
                        // 需要提示方可跳转
                    ];
                tipsRoute = ['/meeting/room', '/simultaneousTranslation/generate'];
                baiRoute = ['/meeting/detail'];
                if (!(tipsRoute.includes(from.name) && !baiRoute.includes(to.name))) return [3 /*break*/, 4];
                _e.label = 1;
            case 1:
                _e.trys.push([1, 3, , 4]);
                return [4 /*yield*/, feedback.confirm('您正在录制中，确定要切换页面吗？')];
            case 2:
                tipsRes = _e.sent();
                return [3 /*break*/, 4];
            case 3:
                error_1 = _e.sent();
                return [2 /*return*/, next(from.fullPath)];
            case 4:
                // 判断权限 非首页
                if (to.path !== '/' && to.path !== '/new-chat') {
                    token = localStorage.getItem('token');
                    if (!token && ((_d = to.meta) === null || _d === void 0 ? void 0 : _d.is_login)) {
                        store.commit('setLoginStatus', { status: true });
                        return [2 /*return*/, next(from.fullPath)];
                    }
                    return [2 /*return*/, next()];
                }
                return [2 /*return*/, next()];
        }
    });
}); });
export default router;
