import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = { class: "flexColumn user_list" };
var _hoisted_2 = ["onClick"];
import { ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'yc-popover-list',
    props: {
        lists: { default: function () { return []; } },
        width: { default: 200 },
        placement: { default: 'bottom-end' },
        trigger: { default: 'click' },
        showBr: { type: Boolean, default: true }
    },
    setup: function (__props, _a) {
        var __expose = _a.expose;
        var popoverRef = ref();
        var getRef = function () { return popoverRef.value; };
        __expose({
            getRef: getRef
        });
        return function (_ctx, _cache) {
            var _component_el_icon = _resolveComponent("el-icon");
            var _component_el_popover = _resolveComponent("el-popover");
            return (_openBlock(), _createBlock(_component_el_popover, {
                ref_key: "popoverRef",
                ref: popoverRef,
                "popper-class": "userpopper",
                placement: _ctx.placement,
                title: "",
                width: _ctx.width,
                trigger: _ctx.trigger,
                content: ""
            }, {
                reference: _withCtx(function () { return [
                    _renderSlot(_ctx.$slots, "content")
                ]; }),
                default: _withCtx(function () { return [
                    _createElementVNode("div", _hoisted_1, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists, function (item, index) {
                            return (_openBlock(), _createElementBlock("div", {
                                class: _normalizeClass(["flexRow-alc ul_li clickable", { br: index > 0 && _ctx.showBr }]),
                                onClick: function ($event) { return (_ctx.$emit('change', item, index)); },
                                key: index
                            }, [
                                (item.icon)
                                    ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                                        default: _withCtx(function () { return [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                                        ]; }),
                                        _: 2
                                    }, 1024))
                                    : _createCommentVNode("", true),
                                _createElementVNode("span", null, _toDisplayString(item.name), 1)
                            ], 10, _hoisted_2));
                        }), 128))
                    ])
                ]; }),
                _: 3
            }, 8, ["placement", "width", "trigger"]));
        };
    }
});
