export default {
    brand_name: 'AI助手',
    employURL: '',
    privacyURL: '',
    baseYM: 'mouse-api.shanghaijimu.com',
    baseURL: 'https://mouse-api.shanghaijimu.com',
    baseURLFile: 'https://convert-test.shanghaijimu.com',
    baseURLWPS: 'https://wps.shanghaijimu.com',
    wss: 'wss:/mouse-api.shanghaijimu.com/ws/tts'
};
