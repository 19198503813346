import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, Transition as _Transition, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "layout flexRow" };
var _hoisted_2 = { class: "sidebar bgImg" };
var _hoisted_3 = {
    class: "main pgFlex",
    style: { "height": "calc(100% - 40px)" }
};
var _hoisted_4 = { class: "music_play" };
import { onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import emitter from '../../utils/eventBus';
import loginView from '../login/login.vue';
import musicChat from '../subview/music/music_chat/music_chat.vue';
import headerView from './components/header.vue';
import sidebar from './components/sidebar.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup: function (__props) {
        var route = useRoute();
        // 显示播放器
        var showPlay = ref(false);
        var musicChatInfo = ref();
        var playMusic = function (value) {
            showPlay.value = true;
            musicChatInfo.value = value;
        };
        onMounted(function () {
            // 关闭音乐播放弹窗
            emitter.on('closeMusic', function () {
                showPlay.value = false;
                musicChatInfo.value = '';
            });
            emitter.on('playMusic', function (e) {
                playMusic(e);
            });
        });
        onUnmounted(function () {
            emitter.off('closeMusic');
            emitter.off('playMusic');
        });
        return function (_ctx, _cache) {
            var _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(sidebar)
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(["pgFlex flexColumn layout_main", { layout_setting: _unref(route).name == '/setting' }])
                }, [
                    _createVNode(headerView),
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_Transition, { name: "el-fade-in" }, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_router_view)
                            ]; }),
                            _: 1
                        })
                    ]),
                    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
                        default: _withCtx(function () { return [
                            _withDirectives(_createElementVNode("div", _hoisted_4, [
                                _createVNode(musicChat, _normalizeProps(_guardReactiveProps(musicChatInfo.value)), null, 16)
                            ], 512), [
                                [_vShow, showPlay.value]
                            ])
                        ]; }),
                        _: 1
                    })
                ], 2),
                _createVNode(loginView)
            ]));
        };
    }
});
