var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import request from '../api/request';
import ppt from './module/ppt';
import tts from './module/tts';
import tcts from './module/tcts';
import intelligence from './module/intelligence';
import { createStore } from 'vuex';
var initQuery = {
    // 对话内容
    keyword: '',
    link: [],
    type: '',
    modelId: '',
    translateType: '',
    writeQuery: {},
    is_create: false,
    images: [],
    model: '',
    fileAnalyseUrl: []
};
export default createStore({
    state: {
        chatQuery: initQuery,
        histroyIndex: 1,
        histroyList: [],
        drawHistroyList: [],
        simpleList: [],
        isCollapse: false,
        loginStatus: false,
        userInfo: {},
        histroyOther: {
            // 历史记录相关
            status: false // 清空历史状态
        }
    },
    getters: {},
    mutations: {
        // 存储对话内容
        setChat: function (state, playload) {
            Object.keys(playload).forEach(function (key) {
                if (state.chatQuery.hasOwnProperty(key)) {
                    state.chatQuery[key] = playload[key];
                }
            });
        },
        // 初始化
        initChat: function (state, playload) {
            state.chatQuery = initQuery; // 对话内容
        },
        // 操作侧边栏
        handleCollapse: function (state, playload) {
            state.isCollapse = playload || false;
        },
        // 获取历史消息
        setHistroyList: function (state, playload) {
            return __awaiter(this, void 0, void 0, function () {
                var res, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, request.get('/app-api/lt/ai/chat/conversation/my-list', {})];
                        case 1:
                            res = _a.sent();
                            state.histroyList = Array.isArray(res === null || res === void 0 ? void 0 : res.data) ? JSON.parse(JSON.stringify(res.data)) : [];
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        // 获取绘画历史消息
        setDrawHistroyList: function (state, playload) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var res, error_2;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, request.post('/app-api/lt/ai/image/getSessionList', {})];
                        case 1:
                            res = _b.sent();
                            state.drawHistroyList = Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.list) ? JSON.parse(JSON.stringify(res.data.list)) : [];
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _b.sent();
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        // 存储智能体列表
        setSimpleList: function (state, playload) {
            return __awaiter(this, void 0, void 0, function () {
                var res, error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, request.get('/app-api/lt/ai/chat/model/simple-list', {
                                    status: 0
                                })];
                        case 1:
                            res = _a.sent();
                            state.simpleList = Array.isArray(res === null || res === void 0 ? void 0 : res.data) ? JSON.parse(JSON.stringify(res.data)) : [];
                            return [3 /*break*/, 3];
                        case 2:
                            error_3 = _a.sent();
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        // 存储登录状态
        setLoginStatus: function (state, playload) {
            state.loginStatus = (playload === null || playload === void 0 ? void 0 : playload.status) || false;
        },
        // 存储用户信息
        setUserInfo: function (state, playload) {
            state.userInfo = playload || {};
        },
        // 历史状态相关
        setHistroyOther: function (state, playload) {
            console.log(88888, playload);
            typeof playload === 'object' &&
                Object.keys(playload).forEach(function (key) {
                    if (key in state.histroyOther) {
                        state.histroyOther[key] = playload[key];
                    }
                });
        },
        // 存储历史选择导航
        setHistroyIndex: function (state, playload) {
            state.histroyIndex = playload || 0;
        },
    },
    actions: {
        // 获取用户信息
        getUserInfo: function (state, playload) {
            return new Promise(function (resolve, reject) {
                request
                    .get('/app-api/member/user/get', {})
                    .then(function (res) {
                    state.commit('setUserInfo', res === null || res === void 0 ? void 0 : res.data);
                    resolve(res);
                })
                    .catch(function (err) {
                    reject(err);
                });
            });
        }
    },
    modules: {
        ppt: ppt,
        tts: tts,
        tcts: tcts,
        intelligence: intelligence
    }
});
