import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/images/back.png';
import _imports_1 from '@/assets/images/collapse_a.svg';
import _imports_2 from '@/assets/images/export.png';
import _imports_3 from '@/assets/images/clear.png';
import _imports_4 from '@/assets/images/call.png';
var _hoisted_1 = { class: "header flexRow-alc flex-jcsb" };
var _hoisted_2 = { class: "flexRow-alc" };
var _hoisted_3 = {
    key: 2,
    style: { "margin-left": "10px" }
};
var _hoisted_4 = { class: "flexRow-alc" };
var _hoisted_5 = { class: "flexRow-alc" };
var _hoisted_6 = {
    key: 1,
    class: "flexRow-alc"
};
var _hoisted_7 = { class: "user flexRow-alc clickable" };
var _hoisted_8 = ["src"];
import { reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import request from '@/api/request';
import feedback from '@/utils/feedback';
import emitter from '@/utils/eventBus';
import { ElMessage } from 'element-plus';
import ycPopoverList from '@/components/yc-popover-list/yc-popover-list.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'header',
    setup: function (__props) {
        var router = useRouter();
        var route = useRoute();
        var store = useStore();
        var ycPopoverListRef = ref();
        // 用户信息
        var userInfo = computed(function () { return store.state.userInfo; });
        var goBack = function () {
            router.go(-1);
        };
        // 标题
        var showTitle = computed(function () { var _a; return ((_a = route.query) === null || _a === void 0 ? void 0 : _a.showTitle) || 0; });
        // 用户操作
        var handleUserChange = function (item, index) {
            var _a, _b;
            if (item.key == 'outlogin') {
                // 退出登录
                localStorage.removeItem('token');
                store.state.userInfo = {};
                router.replace('/');
            }
            console.log(item);
            if (item.key == 'setting') {
                router.push('/setting');
            }
            (_b = (_a = ycPopoverListRef.value) === null || _a === void 0 ? void 0 : _a.getRef()) === null || _b === void 0 ? void 0 : _b.hide();
        };
        // 侧边栏操作
        var isCollapse = computed(function () { return store.state.isCollapse; });
        var handleCollapse = function () {
            store.commit('handleCollapse', !isCollapse.value);
        };
        // 更多操作
        var ycPopoverMoreRef = ref();
        var moreLists = reactive([{ name: '复制文本', key: 'copy' }, { name: '生成TXT', key: 'txt' }, { name: '生成DOC', key: 'doc' }]);
        var handleMore = function (item) {
            var _a, _b;
            switch (item.key) {
                case 'copy': // 复制文本
                    break;
                case 'txt': // 生成TXT
                    break;
                case 'doc': // 生成DOC
                    break;
            }
            (_b = (_a = ycPopoverMoreRef.value) === null || _a === void 0 ? void 0 : _a.getRef()) === null || _b === void 0 ? void 0 : _b.hide();
        };
        // 清空历史
        var handleClear = function () {
            feedback
                .confirm('确定清空对话？')
                .then(function (res) {
                if (res === 'confirm') {
                    emitter.emit('clearHistroy');
                }
            })
                .catch(function () { });
        };
        // 开启语音通话
        var openVoicecall = function () {
            if (route.path == '/voicecall') {
                // 通话页面
                emitter.emit('openVoicecall');
            }
            else {
                // 非通话页面
                router.push('/voicecall');
            }
        };
        // 登录
        var goLogin = function () {
            store.commit('setLoginStatus', { status: true });
        };
        // 清空操作
        var closeChat = function () {
            feedback
                .confirm('确定清空对话？')
                .then(function (res) {
                if (res === 'confirm') {
                    if ((route === null || route === void 0 ? void 0 : route.query.viewType) == 'drawing') {
                        deleteDrawChat();
                    }
                    else {
                        deleteChat();
                    }
                }
            })
                .catch(function () { });
        };
        // 删除对话
        var deleteChat = function () {
            request
                .delete("/app-api/lt/ai/chat/conversation/delete-my?id=".concat(route === null || route === void 0 ? void 0 : route.query.chatid), {})
                .then(function (delRes) {
                if ((delRes === null || delRes === void 0 ? void 0 : delRes.code) == 0) {
                    store.commit('setHistroyList');
                    router.push('/');
                }
            })
                .catch(function () { });
        };
        // 删除绘画对话
        var deleteDrawChat = function () {
            request
                .delete("/app-api/lt/ai/image/deleteSessionById?id=".concat(route === null || route === void 0 ? void 0 : route.query.chatid), {})
                .then(function (delRes) {
                if ((delRes === null || delRes === void 0 ? void 0 : delRes.code) == 0) {
                    router.push('/');
                }
                else {
                    ElMessage.warning(delRes === null || delRes === void 0 ? void 0 : delRes.msg);
                }
            })
                .catch(function () { });
        };
        return function (_ctx, _cache) {
            var _a;
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    (_unref(route).name == '/setting' || _unref(route).name == '/intelligence_fileConversion' || _unref(route).name === '/meeting/detail')
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            class: "clickable flexRow-alc",
                            onClick: goBack
                        }, _cache[0] || (_cache[0] = [
                            _createElementVNode("img", {
                                src: _imports_0,
                                alt: "",
                                style: { "width": "24px" }
                            }, null, -1)
                        ])))
                        : _createCommentVNode("", true),
                    (isCollapse.value)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 1,
                            class: "clickable flexRow-alc",
                            onClick: handleCollapse
                        }, _cache[1] || (_cache[1] = [
                            _createElementVNode("img", { src: _imports_1 }, null, -1)
                        ])))
                        : _createCommentVNode("", true),
                    (showTitle.value == '1')
                        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString((_a = _unref(route).query) === null || _a === void 0 ? void 0 : _a.title), 1))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                    (_unref(route).name === '/simultaneousTranslation/generate')
                        ? (_openBlock(), _createBlock(ycPopoverList, {
                            key: 0,
                            ref_key: "ycPopoverMoreRef",
                            ref: ycPopoverMoreRef,
                            lists: moreLists,
                            onChange: handleMore,
                            showBr: false
                        }, {
                            content: _withCtx(function () { return _cache[2] || (_cache[2] = [
                                _createElementVNode("p", { class: "clickable flexRow-alc" }, [
                                    _createElementVNode("img", {
                                        src: _imports_2,
                                        style: { "width": "24px", "margin-right": "24px" }
                                    })
                                ], -1)
                            ]); }),
                            _: 1
                        }, 8, ["lists"]))
                        : _createCommentVNode("", true),
                    (_unref(store).state.histroyOther.status)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 1,
                            class: "clickable flexRow-alc",
                            onClick: handleClear
                        }, _cache[3] || (_cache[3] = [
                            _createElementVNode("img", {
                                src: _imports_3,
                                style: { "width": "24px", "margin-right": "20px" }
                            }, null, -1)
                        ])))
                        : _createCommentVNode("", true),
                    (_unref(route).query && _unref(route).query.chatid)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 2,
                            class: "clickable flexRow-alc",
                            onClick: closeChat
                        }, _cache[4] || (_cache[4] = [
                            _createElementVNode("img", {
                                src: _imports_3,
                                style: { "width": "24px", "margin-right": "20px" }
                            }, null, -1)
                        ])))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_5, [
                        (!userInfo.value.id)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "login clickable",
                                onClick: goLogin
                            }, "登录"))
                            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("div", {
                                    class: "clickable flexRow-alc flex-jcc",
                                    style: { "margin-right": "20px" },
                                    onClick: openVoicecall
                                }, _cache[5] || (_cache[5] = [
                                    _createElementVNode("img", {
                                        src: _imports_4,
                                        style: { "width": "28px" }
                                    }, null, -1)
                                ])),
                                _createVNode(ycPopoverList, {
                                    ref_key: "ycPopoverListRef",
                                    ref: ycPopoverListRef,
                                    lists: [{ icon: 'Setting', name: '设置', key: 'setting' }, { icon: 'SwitchButton', name: '退出登录', key: 'outlogin' }],
                                    onChange: handleUserChange
                                }, {
                                    content: _withCtx(function () {
                                        var _a;
                                        return [
                                            _createElementVNode("div", _hoisted_7, [
                                                _createElementVNode("img", {
                                                    src: ((_a = userInfo.value) === null || _a === void 0 ? void 0 : _a.avatar) || 'https://ai-web-1320245968.cos.ap-guangzhou.myqcloud.com/comm%2Favatar_g.png'
                                                }, null, 8, _hoisted_8)
                                            ])
                                        ];
                                    }),
                                    _: 1
                                }, 512)
                            ]))
                    ])
                ])
            ]));
        };
    }
});
