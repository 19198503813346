var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElMessage } from 'element-plus';
import request from '../../api/request';
import router from '../../router';
import emitter from '../../utils/eventBus';
var chatFlow = /** @class */ (function () {
    function chatFlow() {
    }
    // 创建聊天
    chatFlow.prototype.createChat = function (model, keyword, viewType, callBack) {
        var _this = this;
        if (callBack === void 0) { callBack = function () { }; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                request
                    .post('/app-api/lt/ai/chat/conversation/create-my', {
                    model: model,
                    title: keyword.slice(0, 50)
                })
                    .then(function (res) {
                    if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                        callBack();
                        resolve(res);
                        router.push("/chat?chatid=".concat(res.data, "&viewType=").concat(viewType));
                    }
                    else if ((res === null || res === void 0 ? void 0 : res.code) != 401) {
                        // reject(res)
                        ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                    }
                })
                    .catch(function (err) {
                    // reject(err)
                });
                return [2 /*return*/];
            });
        }); });
    };
    // 更新聊天
    chatFlow.prototype.updateChat = function (chatid, modelId, title, systemMessage) {
        if (title === void 0) { title = ''; }
        if (systemMessage === void 0) { systemMessage = ''; }
        return new Promise(function (resolve, reject) {
            // 更新会话
            request
                .put('/app-api/lt/ai/chat/conversation/update-my', {
                id: chatid,
                title: title || null,
                modelId: modelId,
                temperature: 1,
                maxTokens: 4096,
                maxContexts: 20,
                systemMessage: systemMessage
            })
                .then(function (updateRes) {
                if ((updateRes === null || updateRes === void 0 ? void 0 : updateRes.code) == 0) {
                    resolve(updateRes);
                }
                else {
                    reject(updateRes);
                    ElMessage.warning(updateRes === null || updateRes === void 0 ? void 0 : updateRes.msg);
                }
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    // 获取聊天详情
    chatFlow.prototype.detailsChat = function (chatid) {
        return new Promise(function (resolve, reject) {
            request
                .get('/app-api/lt/ai/chat/conversation/get-my', {
                id: chatid
            })
                .then(function (res) {
                var _a;
                if (!(res === null || res === void 0 ? void 0 : res.data))
                    return;
                request
                    .get('/app-api/lt/ai/chat/message/list-by-conversation-id', {
                    conversationId: (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.id
                })
                    .then(function (uRes) {
                    if ((uRes === null || uRes === void 0 ? void 0 : uRes.code) == 0) {
                        resolve(uRes.data);
                    }
                    else {
                        reject(uRes);
                    }
                })
                    .catch(function (err) {
                    reject(err);
                });
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    /**
     * ai绘画
     */
    // 生成图片
    chatFlow.prototype.generateImages = function (query, callBack) {
        var _this = this;
        if (callBack === void 0) { callBack = function () { }; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                request
                    .post('/app-api/lt/ai/image/volc/imagine', query)
                    .then(function (res) {
                    if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                        callBack();
                        resolve(res);
                    }
                    else if ((res === null || res === void 0 ? void 0 : res.code) != 401) {
                        ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                    }
                })
                    .catch(function (err) { });
                return [2 /*return*/];
            });
        }); });
    };
    // 查询生成图片
    chatFlow.prototype.queryGenerateImages = function (query, callBack) {
        var _this = this;
        if (callBack === void 0) { callBack = function () { }; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                request
                    .post('/app-api/lt/ai/image/volc/query', query)
                    .then(function (res) {
                    if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                        callBack();
                        resolve(res === null || res === void 0 ? void 0 : res.data);
                    }
                    else if ((res === null || res === void 0 ? void 0 : res.code) != 401) {
                        ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                    }
                })
                    .catch(function (err) { });
                return [2 /*return*/];
            });
        }); });
    };
    // 更新所有生成图片状态
    chatFlow.prototype.queryAndUpdateAll = function (query, callBack) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (callBack === void 0) { callBack = function () { }; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                request
                    .get('/app-api/lt/ai/image/volc/queryAndUpdateAll', query)
                    .then(function (res) {
                    if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                        callBack();
                        resolve(res === null || res === void 0 ? void 0 : res.data);
                    }
                    else if ((res === null || res === void 0 ? void 0 : res.code) != 401) {
                        ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                    }
                })
                    .catch(function (err) { });
                return [2 /*return*/];
            });
        }); });
    };
    // 创建对话
    chatFlow.prototype.createDrawChat = function (name, viewType, callBack) {
        var _this = this;
        if (viewType === void 0) { viewType = 'drawing'; }
        if (callBack === void 0) { callBack = function () { }; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                request
                    .post('/app-api/lt/ai/image/createImageSession', {
                    name: name
                })
                    .then(function (res) {
                    if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                        callBack();
                        resolve(res);
                        router.push("/drawing_chat?chatid=".concat(res.data, "&viewType=").concat(viewType));
                    }
                    else if ((res === null || res === void 0 ? void 0 : res.code) != 401) {
                        ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                    }
                })
                    .catch(function (err) { });
                return [2 /*return*/];
            });
        }); });
    };
    // 更新绘画
    chatFlow.prototype.updateDrawChat = function (id, name) {
        return new Promise(function (resolve, reject) {
            // 更新会话
            request
                .post('/app-api/lt/ai/image/updateSession', {
                id: id,
                name: name
            })
                .then(function (updateRes) {
                if ((updateRes === null || updateRes === void 0 ? void 0 : updateRes.code) == 0) {
                    resolve(updateRes);
                }
                else {
                    reject(updateRes);
                    ElMessage.warning(updateRes === null || updateRes === void 0 ? void 0 : updateRes.msg);
                }
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    // 获取绘画详情
    chatFlow.prototype.detailsDrawChat = function (id, publicStatus) {
        return new Promise(function (resolve, reject) {
            request
                .get('/app-api/lt/ai/image/getSessionDetailById', {
                id: id
            })
                .then(function (uRes) {
                if ((uRes === null || uRes === void 0 ? void 0 : uRes.code) == 0) {
                    resolve(uRes.data);
                }
                else {
                    reject(uRes);
                    ElMessage.warning(uRes === null || uRes === void 0 ? void 0 : uRes.msg);
                }
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    // 获取绘画详情
    chatFlow.prototype.getMyDrawChat = function (id, publicStatus) {
        return new Promise(function (resolve, reject) {
            request
                .get('/app-api/lt/ai/image/get-my', {
                id: id
            })
                .then(function (uRes) {
                if ((uRes === null || uRes === void 0 ? void 0 : uRes.code) == 0) {
                    resolve(uRes.data);
                }
                else {
                    reject(uRes);
                    ElMessage.warning(uRes === null || uRes === void 0 ? void 0 : uRes.msg);
                }
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    /**
     * 音乐生成
     */
    // 创建音乐对话
    chatFlow.prototype.createMusicChat = function (name, viewType, callBack) {
        var _this = this;
        if (callBack === void 0) { callBack = function () { }; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                request
                    .post('/app-api/lt/ai/volc/music/createSession', {
                    name: name
                })
                    .then(function (res) {
                    if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                        callBack();
                        resolve(res);
                    }
                    else {
                        reject(res);
                        ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                    }
                })
                    .catch(function (err) { });
                return [2 /*return*/];
            });
        }); });
    };
    // 获取我的音乐
    chatFlow.prototype.getMyMusic = function (query, callBack) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (callBack === void 0) { callBack = function () { }; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                request.get('/app-api/lt/ai/volc/music/get-my', query).then(function (res) {
                    if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                        callBack();
                        resolve(res === null || res === void 0 ? void 0 : res.data);
                    }
                    else {
                        reject(res);
                        ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                    }
                });
                return [2 /*return*/];
            });
        }); });
    };
    // 更新音乐
    chatFlow.prototype.querySongs = function (callBack) {
        var _this = this;
        if (callBack === void 0) { callBack = function () { }; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                request.get('/app-api/lt/ai/volc/music/querySongs', {}).then(function (res) {
                    if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                        resolve(res);
                        callBack();
                    }
                    else {
                        reject(res);
                        ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                    }
                });
                return [2 /*return*/];
            });
        }); });
    };
    // 获取歌曲
    chatFlow.prototype.genSong = function (query, callBack) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (callBack === void 0) { callBack = function () { }; }
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                request.post('/app-api/lt/ai/volc/music/genSong', query).then(function (res) {
                    var _a;
                    if ((res === null || res === void 0 ? void 0 : res.code) == 0) {
                        resolve(res === null || res === void 0 ? void 0 : res.data);
                        emitter.emit('playMusic', {
                            sessionId: (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.id
                        });
                        callBack();
                    }
                    else {
                        reject(res);
                        ElMessage.warning(res === null || res === void 0 ? void 0 : res.msg);
                    }
                });
                return [2 /*return*/];
            });
        }); });
    };
    // 更新音乐
    chatFlow.prototype.updateMusicChat = function (id, name) {
        return new Promise(function (resolve, reject) {
            // 更新会话
            request
                .post('/app-api/lt/ai/volc/music/updateSession', {
                id: id,
                name: name
            })
                .then(function (updateRes) {
                if ((updateRes === null || updateRes === void 0 ? void 0 : updateRes.code) == 0) {
                    resolve(updateRes);
                }
                else {
                    reject(updateRes);
                    ElMessage.warning(updateRes === null || updateRes === void 0 ? void 0 : updateRes.msg);
                }
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    // 获取音乐详情
    chatFlow.prototype.getSessionDetail = function (id) {
        return new Promise(function (resolve, reject) {
            request
                .get('/app-api/lt/ai/volc/music/getSessionDetail', {
                id: id
            })
                .then(function (updateRes) {
                if ((updateRes === null || updateRes === void 0 ? void 0 : updateRes.code) == 0) {
                    resolve(updateRes === null || updateRes === void 0 ? void 0 : updateRes.data);
                }
                else {
                    reject(updateRes);
                    ElMessage.warning(updateRes === null || updateRes === void 0 ? void 0 : updateRes.msg);
                }
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    return chatFlow;
}());
var chat = new chatFlow();
export default chat;
